import React, { Component } from 'react';
import { Box, Card, CardContent, Grid, Button, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography';
import { LoginOutlined } from '@mui/icons-material';

import './Fonts.css'
import './Home.css'
import { useEffect } from 'react';

const Home = () => {

    // Please forgive me for this
    // This code resizes the elements of the AP cards in order to have them all aligned perfectly
    const handleResize = () => {
        var heightsAreEqual = true;
        var apSubtitles = document.getElementsByClassName('aPSubtitle');
        var apDescriptions = document.getElementsByClassName('aPDescription');
        var cardActions = document.getElementsByClassName('cardActions');
        var thisHeight = 0;
        var maxHeight = apSubtitles[0].offsetHeight;
        var heighestIndex = 0;

        // If subtitles are not of equal heights, apply margin to center descriptions
        for (var i = 1; i < apSubtitles.length; i++) {
            thisHeight = apSubtitles[i].offsetHeight;
            if (thisHeight > maxHeight) {
                heightsAreEqual = false;
                maxHeight = thisHeight;
                heighestIndex = i;
            }
        }

        if (heightsAreEqual == false) {
            for (var i = 0; i < apDescriptions.length; i++) {
                // No margin needed for the biggest card
                if (i != heighestIndex) {
                    apDescriptions[i].style.marginTop = maxHeight + 'px';
                }
            }
        }
        else {
            for (var i = 0; i < apDescriptions.length; i++) {
                // No margin needed for the biggest card
                if (i != heighestIndex) {
                    apDescriptions[i].style.marginTop = 0 + 'px';
                }
            }
        }

        heightsAreEqual = true;
        maxHeight = apDescriptions[0].offsetHeight;
        // If descriptions are not of equal heights, apply margin to center the action buttons
        for (var i = 1; i < apDescriptions.length; i++) {
            thisHeight = apDescriptions[i].offsetHeight;
            if (thisHeight > maxHeight) {
                heightsAreEqual = false;
                maxHeight = thisHeight;
                heighestIndex = i;
            }
        }

        if (heightsAreEqual == false) {
            for (var i = 0; i < apDescriptions.length; i++) {
                // No margin needed for the biggest card
                if (i != heighestIndex) {
                    apDescriptions[i].parentElement.style.marginBottom = maxHeight - apDescriptions[i].offsetHeight + 'px';
                }
            }
        }
        else {
            for (var i = 0; i < apDescriptions.length; i++) {
                // No margin needed for the biggest card
                if (i != heighestIndex) {
                    apDescriptions[i].parentElement.style.marginBottom = 0 + 'px';
                }
            }
        }

        heightsAreEqual = true;
        maxHeight = cardActions[0].offsetHeight;
        // If buttons are not of equal heights, apply margin to center the action buttons
        for (var i = 1; i < cardActions.length; i++) {
            thisHeight = cardActions[i].offsetHeight;
            if (thisHeight > maxHeight) {
                heightsAreEqual = false;
                maxHeight = thisHeight;
                heighestIndex = i;
            }
        }

        if (heightsAreEqual == false) {
            for (var i = 0; i < cardActions.length; i++) {
                // No margin needed for the biggest card
                if (i != heighestIndex) {
                    apDescriptions[i].parentElement.style.marginBottom = apDescriptions[i].parentElement.style.marginBottom + (maxHeight - cardActions[i].offsetHeight) / 2 + 'px';
                }
            }
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize, false);
    }, [])


    //Grille(à partir de la ligne 131) à dupliquer dans le cas de l'ajout d'un autre AP sur la page
    return (
        <>
            <Grid container spacing={6} className='cardsContainer'>
                <Grid item lg={4}>
                    <div className="clickableTitle" onClick={() => { window.location.href = 'https://ap-kaftrio-2-5-ans-public-signup.icta.fr/' }}>
                        <Box sx={{ minWidth: 150, height: '100%' }}>
                            <Card variant="outlined" className="aPCard">
                                <CardContent>
                                    <Grid container spacing={0} className='titleGrid'>
                                        <Grid item xs={11}>
                                            <h1 className='aPTitle'> AP1 Kaftrio 2-5 ans
                                            </h1>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <LoginOutlined className='loginIcon' />
                                        </Grid>
                                    </Grid>
                                    <h2 className='aPSubtitle'>
                                    </h2>
                                </CardContent>
                                <CardContent>
                                    <p className='aPDescription'>KAFTRIO est indiqué en association avec KALYDECO dans le traitement des enfants atteints de mucoviscidose âgés de 2 à moins de 6 ans porteurs d’au moins une mutation F508del du gène CFTR (cystic fibrosis transmembrane conductance regulator)</p>
                                </CardContent>
                                <CardContent>
                                    <Grid container spacing={2} className='cardActions'>
                                        <Grid item xs={3} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open('/documents/kaftrio25/kaftrio-kalydeco__decision_et_avisct_ap235.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>Avis HAS</Button>
                                        </Grid>
                                        <Grid item xs={3} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open('https://www.has-sante.fr/plugins/ModuleXitiKLEE/types/FileDocument/doXiti.jsp?id=p_3460547', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>PUT-RD</Button>
                                        </Grid>
                                        <Grid item xs={3} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open('/documents/kaftrio25/Kaftrio_25 RCP_AP1.PDF', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>RCP KAFTRIO</Button>
                                        </Grid>
                                        <Grid item xs={3} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open('/documents/kaftrio25/Kalydeco_25 RCP_AP1.PDF', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>RCP KALYDECO</Button>
                                        </Grid>
                                        <Grid item xs={4} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open("/documents/kaftrio25/PUT-RD - Note d'info.pdf", '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Notice d’information</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    </div>
                </Grid>

                <Grid item lg={4}>
                    <div className="clickableTitle" onClick={() => { window.location.href = 'https://ap-orkambi-public-signup.icta.fr' }}>
                        <Box sx={{ minWidth: 150, height: '100%' }}>
                            <Card variant="outlined" className="aPCard">
                                <CardContent>
                                    <Grid container spacing={0} className='titleGrid'>
                                        <Grid item xs={11}>
                                            <h1 className='aPTitle'> AP2 ORKAMBI
                                            </h1>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <LoginOutlined className='loginIcon' />
                                        </Grid>
                                    </Grid>
                                    <h2 className='aPSubtitle'>
                                    </h2>
                                </CardContent>
                                <CardContent className="cardDescription">
                                    <p className='aPDescription'>L'accès précoce ORKAMBI est désormais terminé. Le traitement Orkambi granulés pour les patients âgés de 1 à 2 ans peut maintenant être prescrit sur ordonnance pour délivrance en officine.</p>
                                </CardContent>
                            </Card>
                        </Box>
                    </div>
                </Grid>

                <Grid item lg={4}>
                    <div className="clickableTitle" onClick={() => { window.location.href = 'https://AP-KAFTRIO-6-ANS-ET-PLUS-public-signup.icta.fr' }}>
                        <Box sx={{ minWidth: 150, height: '100%' }}>
                            <Card variant="outlined" className="aPCard">
                                <CardContent>
                                    <Grid container spacing={0} className='titleGrid'>
                                        <Grid item xs={11}>
                                            <h1 className='aPTitle'> AP1 KAFTRIO NON F
                                            </h1>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <LoginOutlined className='loginIcon' />
                                        </Grid>
                                    </Grid>
                                    <h2 className='aPSubtitle'>
                                    </h2>
                                </CardContent>
                                <CardContent className="cardDescription">
                                    <p className='aPDescription'>KAFTRIO est indiqué en association avec KALYDECO dans le traitement des patients atteints de mucoviscidose, âgés de 6 ans et plus, non porteurs d’une mutation F508del du gène CFTR (cystic fibrosis transmem-brane conductance regulator) et porteurs d’une mutation répondeuse à ivacaftor/tezacaftor/elexacaftor sur la base de données in vitro disponibles.</p>
                                </CardContent>
                                <CardContent>
                                    <Grid container spacing={2} className='cardActions'>
                                        <Grid item xs={3} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open('/documents/kaftriononf/kaftrio_ap227_decision_et_avisct.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>Avis HAS</Button>
                                        </Grid>
                                        <Grid item xs={3} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open("/documents/kaftriononf/put-rd_-_ap1_kaftrio-kalydeco_-_ct_ap-227_-_6_ans_et_plus_vcellule_2023OCT27.pdf", '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>PUT-RD</Button>
                                        </Grid>
                                        <Grid item xs={3} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open('/documents/kaftriononf/KAFTRIO 6-RCP notice  AP1-227 - rectificatif 2 clean.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>RCP KAFTRIO</Button>
                                        </Grid>
                                        <Grid item xs={3} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open('/documents/kaftriononf/KALYDECO 6 - RCP notice AP1-227 - rectificatif 2 clean.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>RCP KALYDECO</Button>
                                        </Grid>
                                        <Grid item xs={4} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open("/documents/kaftriononf/AP_Kaftrio_nonF_notice information patient_version du 27OCT2023.pdf", '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Notice d’information</Button>
                                        </Grid>
                                        <Grid item xs={4} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open("/documents/kaftriononf/AP_Kaftrio_nonF_questionnaires_ version du 27OCT2023.pdf", '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Questionnaires CFQ-R</Button>
                                        </Grid>
                                        <Grid item xs={4} className='cardAction'>
                                            <Button variant='contained' size="large" onClick={(event) => { window.open("/documents/kaftriononf/AP_Kaftrio_nonF_liste des mutations_ version du 27OCT2023.pdf", '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Mutations autorisées</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    </div>
                </Grid>
            </Grid>

        </>
    );
}

export default Home;