import React, { Component } from 'react';
import {  Box } from "@mui/material"

import './Fonts.css'
import './Footer.css'
import { useEffect } from 'react';

export const Footer = () => {

    const handleResize = () => {
        var footer = document.getElementById('footerBox');
        console.log(window.innerWidth, window.innerHeight)

        if (window.innerHeight < 1080) {
            footer.classList.remove('footerBoxLarge');
            footer.classList.add('footerBoxSmall');
        }
        else {
            console.log("add style");
            footer.classList.remove('footerBoxSmall');
            footer.classList.add('footerBoxLarge');
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize, false);
    }, [])

    return (
        <Box id="footerBox" className="footerBoxLarge">
            <p>Les accès aux plateformes des accès précoces du laboratoire VERTEX Pharmaceuticals sont sécurisés et nécessitent des codes de connexion. Cliquez sur le lien suivant pour accéder aux <a target="_blank" href="/documents/Mentions_Legales_Vertex.pdf">mentions légales de VERTEX</a>.</p>
        </Box>
    )
  
}